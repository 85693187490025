
  export default {
    data() {
      return {
        discountSwitch: false,
        contingents: [
          {
            color: '#E4BF7B',
            name: '25 Stellenanzeigen',
            price_per_listing: '35,60 €',
            discounted_price_per_listing: '26,70 €',
            price_per_quota: '890,00 €',
            discounted_price_per_quota: '667,50 €',
          },
          {
            color: '#DCA441',
            name: '50 Stellenanzeigen',
            price_per_listing: '27,80 €',
            discounted_price_per_listing: '20,85 €',
            price_per_quota: '1.390,00 €',
            discounted_price_per_quota: '1.042,50 €',
          },
          {
            color: '#6E8DB5',
            name: '75 Stellenanzeigen',
            price_per_listing: '22,53 €',
            discounted_price_per_listing: '16,90 €',
            price_per_quota: '1.690,00 €',
            discounted_price_per_quota: '1.267,50 €',
          },
          {
            color: '#3D6190',
            name: '100 Stellenanzeigen',
            price_per_listing: '19,90 €',
            discounted_price_per_listing: '14,93 €',
            price_per_quota: '1.990,00 €',
            discounted_price_per_quota: '1.492,50 €',
          },
        ],
      };
    },
  };
